import { Fragment, useEffect } from 'react'
import {
  FUNDING,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import { api } from '../../api'
import { safeResponseData } from 'resource-endpoint'

// This values are the props in the UI
const style = { layout: 'vertical' }

class CreateCaptureError extends Error {}

// Custom component to wrap the PayPalButtons and handle currency changes
export const PaypalButtonWrapper = ({
  callback,
  disabled,
  name,
  amount,
  message,
  showSpinner,
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer()
  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
      },
    })
  }, [showSpinner])

  return (
    <PayPalButtons
      style={style}
      disabled={disabled || isPending}
      forceReRender={[name, amount, message, style]}
      fundingSource={FUNDING.PAYPAL}
      createOrder={() => {
        return api.payPal
          .create(amount)
          .then(response => {
            const { order, errors } = safeResponseData(response)
            if (errors) {
              callback({ success: false, errors })
              console.error('errors in createOrder', errors)
              throw new CreateCaptureError()
            }
            return order.id
          })
          .catch(err => {
            if (err instanceof CreateCaptureError) {
              // errors passed to callback to be shown to user.
              return
            }
            console.error('Error while calling api.payPal.create', err)
            callback({
              success: false,
              errors: { message: 'Something went wrong. Please try again.' },
            })
            throw err
          })
      }}
      onApprove={function ({ orderID: order_id }) {
        return api.payPal
          .capture({
            order_id,
            name: name || undefined,
            message: message || undefined,
          })
          .then(response => {
            const { order, errors } = safeResponseData(response)
            if (errors) {
              callback({ success: false, errors })
              throw new CreateCaptureError()
            }
            callback({ success: 'Donation Succeeded', errors: false })
          })
          .catch(err => {
            if (err instanceof CreateCaptureError) {
              // errors passed to callback to be shown to user.
              return
            }
            console.error('Error while calling api.payPal.capture', err)
            callback({
              success: false,
              errors: { message: 'Something went wrong. Please try again.' },
            })
          })
      }}
    />
  )
}
