import { DonationsV1 } from '../../components/donations'
import Container from '@mui/material/Container'
import { AdminPageV2 } from './v2'

export const AdminPage = () => {
  const useV2 = false
  if (useV2) {
    return <AdminPageV2 />
  }
  return (
    <Container>
      <DonationsV1 />
    </Container>
  )
}
