import { api } from './index'
import { safeResponseData } from 'resource-endpoint'

// Usage shown in async functions, likely placed in the component onSubmit for the form
// Not created as helper function necessarily as shown below.

export const currentUser = async () => {
  const { user, errors } = safeResponseData(await api.user.current())
  return { errors, user }
}

export const confirmPassword = async values => {
  const { errors, confirmedAt } = safeResponseData(
    await api.user.confirmPassword(values.password),
  )
  return { errors, confirmedAt }
}

export const logoutUser = async () => {
  const { errors } = safeResponseData(await api.auth.logout())
  return { errors }
}

export const loginUser = async (email, password) => {
  const { user, errors } = safeResponseData(
    await api.auth.login({ email, password }),
  )
  return { errors, user }
}

export const registerUser = async values => {
  const { user, errors } = safeResponseData(
    await api.auth.register({
      email: values.email,
      emailConfirmation: values.emailConfirmation,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      name: values.name,
    }),
  )
  return { errors, user }
}

/**
 *
 * @param {string=} since
 * @param {number=} page
 * @param {number=} perPage
 * @returns {Promise<{pagination: Pick<any, Exclude<keyof any, "errors">>, errors}>}
 */
export const getDonations = async (since, page, perPage) => {
  const { errors, ...pagination } = safeResponseData(
    await api.donation.getDonations(since, page, perPage),
  )
  return { pagination, errors }
}

/**
 *
 * @param {string} file
 * @param {string} type
 * @returns {Promise<HTMLAudioElement>}
 */
export const messageAudio = async ({ file, type }) => {
  // fetch is in all modern browsers, so make sure to use one. LOL
  const blob = await fetch(`data:${type};base64,` + file).then(res =>
    res.blob(),
  )
  const url = window.URL.createObjectURL(blob)
  const audio = new Audio()
  audio.src = url
  return audio
}

/**
 *
 * @param {number} id
 * @returns {Promise<{audio: HTMLAudioElement=, errors: object=}>}
 */
export const donationMessageAudio = async id => {
  const { file, type, errors } = safeResponseData(
    await api.donation.fetchDonationAudio(id),
  )
  if (errors) {
    return { audio: undefined, errors }
  }
  const audio = await messageAudio({ file, type })
  return { errors, audio }
}
