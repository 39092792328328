import { Fragment, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import { Add, Pause, PlayArrow, Remove } from '@mui/icons-material'
import { useStateProvider } from '../../context'
import { green } from '@mui/material/colors'
import { donationMessageAudio, getDonations } from '../../api/helpers'

export const DonationsV1 = () => {
  const { state } = useStateProvider()
  const [errors, setErrors] = useState()
  const [donations, setDonations] = useState()
  const [playingQ, setPlayingQ] = useState(new Map())
  const [playingDonation, setPlayingDonation] = useState(null)
  const [media, setMedia] = useState(null)
  const [donationPage, setDonationPage] = useState(0)
  const [maxDonationPage, setMaxDonationPage] = useState(0)
  const qKeys = [...playingQ.keys()]

  useEffect(() => {
    if (media) {
      if (media.paused) {
        media.audio?.pause()
      } else {
        media.audio
          ?.play()
          .then(() => media.audio?.addEventListener('ended', audioEndedHandler))
          .catch(e => console.error('audio.play error', e))
      }
    }
    return () => media?.audio?.removeEventListener('ended', audioEndedHandler)
  }, [media])

  useEffect(() => {
    if (media?.audioID !== playingDonation?.id) {
      initializeMedia(playingDonation)
    }
  }, [playingDonation])

  // TODO: Add loading/empty/error state
  // TODO: Switch to react-query useDonationPagination
  //       once errors can be handled correctly without being cached.
  let timeout
  useEffect(() => {
    if (!donations) {
      getDonations(undefined).then(data => {
        if (data.errors) {
          setErrors(data.errors)
        } else if (data.pagination) {
          setDonations(data.pagination.data)
          setDonationPage(data.pagination.current_page)
          setMaxDonationPage(data.pagination.last_page)
        }
      })
    } else if (typeof timeout !== 'number') {
      // every thirty seconds grabs newest donos for page, but if you are on page, wipes state, need to fix with Rquery
      timeout = setTimeout(() => {
        getDonations(undefined, donationPage).then(data => {
          if (data.errors) {
            setErrors(data.errors)
          } else if (data.pagination) {
            setDonations(data.pagination.data)
          }
        })
      }, 30000)
    }
    return () => typeof timeout === 'number' && clearTimeout(timeout)
  }, [donations])

  useEffect(() => {
    getDonations(undefined, donationPage).then(data => {
      if (data.errors) {
        setErrors(data.errors)
      } else if (data.pagination) {
        setDonations(data.pagination.data)
      }
    })
  }, [donationPage])

  const dispatchOverlayEvent = (donation, action) => {
    // @see https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
    return state.overlay?.dispatchEvent(
      new CustomEvent('overlay', {
        detail: {
          ...donation,
          timestamp: new Date().getMilliseconds(),
          action,
        },
      }),
    )
  }

  const initializeMedia = donation => {
    donationMessageAudio(donation.id).then(({ errors, audio }) => {
      if (audio) {
        setMedia({
          audio,
          audioID: donation.id,
          paused: !!donation.paused,
        })
      } else {
        console.error('handle donationMessageAudio errors', errors)
      }
    })
  }

  const playNextDonation = () => {
    //infinite loop, rework play/pause
    if (qKeys.length > 0) {
      // playDonation(playingQ.get(qKeys[0]))
    }
  }

  const audioEndedHandler = event => {
    removeFromQ(media.audioID)
    dispatchOverlayEvent(null, 'hide')
    setMedia(null)
    media.audio?.removeEventListener('ended', audioEndedHandler)
    // TODO: Bug fix removeFromQ doesn't change playingQ before next donation goes off
    setTimeout(() => {
      playNextDonation()
    }, 3000)
  }

  const playDonation = donation => {
    setPlayingQ(prevState => {
      return new Map([
        ...Array.from(prevState).map(([key, item]) => [
          key,
          {
            ...item,
            paused: donation.id !== key,
          },
        ]),
      ])
    })
    setPlayingDonation({
      ...donation,
      paused: false,
    })
    dispatchOverlayEvent(donation, 'show')
  }

  const stopDonation = donation => {
    setPlayingQ(prevState => {
      const newQ = new Map(prevState.entries())
      newQ.set(donation.id, {
        ...donation,
        paused: true,
      })
      return newQ
    })
  }

  const addToQ = donation => {
    setPlayingQ(prevState => {
      const newQ = new Map(prevState.entries())
      newQ.set(donation.id, {
        ...donation,
        timestamp: new Date().getMilliseconds(),
        paused: true,
      })
      return newQ
    })
  }

  const removeFromQ = donationID => {
    setPlayingQ(prevState => {
      const newQ = new Map(prevState.entries())
      newQ.delete(donationID)
      return newQ
    })
  }

  const handlePaginationChange = (event, value) => {
    setDonationPage(value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {/* TODO: Add logic, example {"message":"Unauthenticated."} */}
          {errors && JSON.stringify(errors)}
          {donations &&
            donations.map((donation, donationKey) => {
              const qIndex = qKeys.indexOf(donation.id)
              const isInQ = qIndex >= 0
              return (
                <Fragment key={`Donation-Item-${donationKey}`}>
                  <ListItem
                    sx={{
                      width: '100%',
                      bgcolor: isInQ ? green[100] : 'none',
                    }}
                  >
                    <ListItemIcon>{isInQ && qIndex + 1}</ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h6"
                            sx={{ paddingRight: 1 }}
                          >
                            {donation.name ? donation.name : 'Anonymous'} -{' '}
                          </Typography>
                          <Typography
                            component="h6"
                            variant="h6"
                            sx={{ color: green[700] }}
                          >
                            £{donation.amount}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Typography
                          sx={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          component="p"
                        >
                          {donation.message}
                        </Typography>
                      }
                    />
                    {isInQ ? (
                      <Fragment>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFromQ(donation.id)}
                        >
                          <Remove />
                        </IconButton>
                        {playingQ.get(donation.id)?.paused ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => playDonation(donation)}
                          >
                            <PlayArrow />
                          </IconButton>
                        ) : (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => stopDonation(donation)}
                          >
                            <Pause />
                          </IconButton>
                        )}
                      </Fragment>
                    ) : (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => addToQ(donation)}
                      >
                        <Add />
                      </IconButton>
                    )}
                  </ListItem>
                  {donationKey !== donations.length - 1 && (
                    <Divider component="li" />
                  )}
                </Fragment>
              )
            })}
        </List>
        <Pagination
          count={maxDonationPage}
          page={donationPage}
          onChange={handlePaginationChange}
        />
      </Box>
    </Box>
  )
}
