import { Options, setGlobalOptions } from 'resource-endpoint'
import { Auth } from './auth'
import { Donation } from './donation'
import { PayPal } from './paypal'
import { User } from './user'

class Api {
  /**
   *
   * @returns {Auth}
   */
  get auth() {
    return new Auth()
  }

  /**
   *
   * @returns {Donation}
   */
  get donation() {
    return new Donation()
  }

  /**
   *
   * @returns {PayPal}
   */
  get payPal() {
    return new PayPal()
  }

  /**
   * @returns {User}
   */
  get user() {
    return new User()
  }
}

export const api = new Api()

export class ClientOptions extends Options {
  constructor(options) {
    super({
      path: process.env.REACT_APP_API_PATH ?? 'api',
      withCredentials: true,
      ...options,
    })
  }
}

export const configure = options => {
  setGlobalOptions(new ClientOptions(options))
}

// set ClientOptions as default globalOptions singleton when this module is loaded
// This library is very specific so it's okay, this library should not be used except for
// frontend usage, otherwise this may create confusion.
configure()
