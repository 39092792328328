(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("qs"), require("url-join"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "qs", "url-join"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("axios"), require("qs"), require("url-join")) : factory(root["axios"], root["qs"], root["url-join"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__103__, __WEBPACK_EXTERNAL_MODULE__423__, __WEBPACK_EXTERNAL_MODULE__220__) => {
return 