import { Fragment } from 'react'
import { useStateProvider } from '../../context'
import { useLocation, useNavigate } from 'react-router-dom'
import { logoutUser } from '../../api/helpers'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import * as AppRoutes from '../../constants/routes'
import { closeFFPromotionPopup, openFFPromotionPopup } from '../overlay'
// import IconButton from '@mui/material/IconButton'
// import MenuIcon from '@mui/icons-material/Menu'

export const Navigation = () => {
  const { state, helpers } = useStateProvider()
  const setOverlay = helpers.setOverlay.bind(helpers)
  let location = useLocation()
  let navigate = useNavigate()
  const doLogout = () => {
    logoutUser().then(({ errors }) => {
      if (!errors) {
        helpers.setUser(null)
      } else {
        console.log('Errors on logout...', errors)
      }
    })
  }
  if (location.pathname === AppRoutes.OVERLAY) {
    return null
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            >
            <MenuIcon />
            </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {location.pathname}
          </Typography>
          <Button onClick={() => navigate(AppRoutes.HOME)} color="inherit">
            HOME
          </Button>
          {state.user && (
            <Fragment>
              {state.overlay ? (
                <Button
                  onClick={() =>
                    closeFFPromotionPopup(state.overlay, setOverlay)
                  }
                  color="inherit"
                >
                  CLOSE
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    openFFPromotionPopup(state.overlay, setOverlay)
                  }
                  color="inherit"
                >
                  OVERLAY
                </Button>
              )}
              <Button onClick={() => navigate(AppRoutes.ADMIN)} color="inherit">
                ADMIN
              </Button>
              <Button onClick={doLogout} color="inherit">
                LOGOUT
              </Button>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
