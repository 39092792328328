import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as AppRoutes from '../constants/routes'
import { Navigation } from '../components/navigation'
import { HomePage } from '../pages/home'
import { OverlayPage } from '../pages/overlay'
import { AdminPage } from '../pages/admin'
import { LoginPage, RegisterPage } from '../pages/auth'
import { AuthPageWrapper, DetectAuth } from '../components/auth'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { Store } from '../context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ORDER } from '../constants/routes'
import { OrderDetailsPage } from '../pages/admin/order'

// TODO: Double check on docs around configure this.
// @see https://react-query.tanstack.com/reference/QueryClientProvider
// @see https://react-query.tanstack.com/reference/QueryClient
const queryClient = new QueryClient()

const initialOptions = {
  'client-id': process.env.REACT_APP_PAYPAY_CLIENT_ID,
  currency: 'GBP',
  intent: 'capture',
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Store>
        <DetectAuth>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path={AppRoutes.REGISTER} element={<RegisterPage />} />
              <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
              <Route
                path={AppRoutes.OVERLAY}
                element={
                  <AuthPageWrapper>
                    <OverlayPage />
                  </AuthPageWrapper>
                }
              />
              {/* TODO: Add Group/Nested /admin logic via Router */}
              <Route
                path={AppRoutes.ORDER}
                element={
                  <AuthPageWrapper>
                    <OrderDetailsPage />
                  </AuthPageWrapper>
                }
              />
              <Route
                path={AppRoutes.ADMIN}
                element={
                  <AuthPageWrapper>
                    <AdminPage />
                  </AuthPageWrapper>
                }
              />
              <Route
                path={AppRoutes.HOME}
                element={
                  <PayPalScriptProvider options={initialOptions}>
                    <HomePage />
                  </PayPalScriptProvider>
                }
              />
            </Routes>
          </BrowserRouter>
        </DetectAuth>
      </Store>
    </QueryClientProvider>
  )
}

export default App
