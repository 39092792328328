import * as AppRoutes from '../../constants/routes'

export const openFFPromotionPopup = (overlay, setOverlay) => {
  if (overlay == null || overlay.closed) {
    /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
    setOverlay(
      window.open(
        AppRoutes.OVERLAY,
        '_blank',
        'popup=true, width=1920, height=128',
      ),
    )
    /* then create it. The new window will be created and
         will be brought on top of any other window. */
  } else {
    overlay.focus()
    /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
  }
}

export const closeFFPromotionPopup = (overlay, setOverlay) => {
  overlay.close()
  setOverlay()
}
