import {
  ApiMixin,
  AxiosErrorMixin,
  HandleErrorMixin,
  SessionCsrfCookieMixin,
  CrudEndpoint,
  FormMixin,
} from 'resource-endpoint'

class PayPalBase extends SessionCsrfCookieMixin(
  ApiMixin(FormMixin(CrudEndpoint)),
) {
  // This is the last part of the url path, usually the resource name.
  // e.g. "user" or "profile"
  get resource() {
    return 'paypal/order'
  }

  async create(amount) {
    return this.post('create', {
      data: {
        amount,
        // TODO: Place in command variable
        currency_code: 'GBP',
      },
    })
  }

  /**
   *
   * @param {{order_id: string, payer_id: string, amount: number, payment_gateway_id: string, name: string=, message: string=}} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async capture(data) {
    return this.post('capture', {
      data,
    })
  }
}

class PayPalQuery extends AxiosErrorMixin(PayPalBase) {}

export class PayPal extends HandleErrorMixin(PayPalBase) {
  /**
   *
   * @returns {PayPalQuery}
   */
  get reactQuery() {
    return new PayPalQuery()
  }
}
