import {
  useLocation,
  Navigate,
  useHref,
  useLinkClickHandler,
} from 'react-router-dom'
import { useStateProvider } from '../../context'
import * as AppRoutes from '../../constants/routes'
import { loginUser } from '../../api/helpers'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Copyright } from '../../components/auth'
import { useFeatures } from '../../hooks/features'

const theme = createTheme()

export const LoginPage = () => {
  const { state, helpers } = useStateProvider()
  const { isActive } = useFeatures()
  const location = useLocation()
  const registerOnClick = useLinkClickHandler(AppRoutes.REGISTER)
  const registerHref = useHref(AppRoutes.REGISTER)
  if (location.pathname === '/login' && state.user) {
    return <Navigate replace to="/" />
  }

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    loginUser(data.get('email'), data.get('password')).then(
      ({ user, errors }) => {
        if (errors) {
          // TODO: Implement error handling.
          console.log('Errors while attempting login', errors)
        } else {
          helpers.setUser(user)
        }
      },
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            height: 'calc(100vh - 64px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me" /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}
                {isActive('register') && (
                  <Grid item>
                    <Link
                      onClick={registerOnClick}
                      href={registerHref}
                      variant="body2"
                    >
                      {"Don't have an account? Register"}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
