import {
  ApiMixin,
  AxiosErrorMixin,
  HandleErrorMixin,
  SessionCsrfCookieMixin,
  CrudEndpoint,
  FormMixin,
} from 'resource-endpoint'

/**
 * @module Donation
 */

/**
 * @typedef Donation.Pagination
 * @type object
 * @property {number} current_page
 * @property {object[]} data
 * @property {string} first_page_url
 * @property {number} from
 * @property {number} last_page
 * @property {string} last_page_url
 * @property {object[]} links
 * @property {string} next_page_url
 * @property {string} path
 * @property {number} per_page
 * @property {string=} prev_page_url
 * @property {number} to
 * @property {number} total
 */

class DonationBase extends SessionCsrfCookieMixin(
  ApiMixin(FormMixin(CrudEndpoint)),
) {
  // This is the last part of the url path, usually the resource name.
  // e.g. "user" or "profile"
  get resource() {
    return 'donation'
  }

  /**
   *
   * @param {string=} since
   * @param {number=} page
   * @param {number=} perPage
   * @returns {Promise<AxiosResponse<Donation.Pagination>>}
   */
  async getDonations(since, page, perPage = 10) {
    return this.get(undefined, {
      params: { since, page, perPage },
    })
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOrder(id) {
    return this.get(`order/${id}`)
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<AxiosResponse<{ errors: object[] } | { file: string, type: string }>>}
   */
  async fetchDonationAudio(id) {
    return this.post(`message-audio/${id}`)
  }
}

class DonationQuery extends AxiosErrorMixin(DonationBase) {}

export class Donation extends HandleErrorMixin(DonationBase) {
  /**
   *
   * @returns {DonationQuery}
   */
  get reactQuery() {
    return new DonationQuery()
  }
}
