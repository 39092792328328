import {
  AuthEndpoint,
  ApiMixin,
  AxiosErrorMixin,
  HandleErrorMixin,
  SessionCsrfCookieMixin,
  FormMixin,
} from 'resource-endpoint'

class AuthBase extends SessionCsrfCookieMixin(
  ApiMixin(FormMixin(AuthEndpoint)),
) {}

class AuthQuery extends AxiosErrorMixin(AuthBase) {}

export class Auth extends HandleErrorMixin(AuthBase) {
  /**
   *
   * @returns {AuthQuery}
   */
  get reactQuery() {
    return new AuthQuery()
  }
}
