import { Box, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { blue, red } from '@mui/material/colors'
import OverlayImage from '../../resources/overlay.png'

export const OverlayPage = () => {
  const [playing, setPlaying] = useState(null)
  const [show, setShow] = useState(false)

  // TODO: Set logic for calculating next from queue after playing has finished.
  // 	     Also check for possible events to cancel playing? e.g. pause?
  useEffect(() => {
    const overlayHandler = event => {
      if (event?.detail?.action) {
        if (event.detail.action === 'show') {
          setPlaying({
            ...event.detail,
          })
          setShow(true)
        } else if (event.detail.action === 'hide') {
          setTimeout(() => {
            setShow(false)
          }, 3000)
        }
      } else {
        console.log('Invalid event dispatched to event listener', event)
      }
    }
    window.addEventListener('overlay', overlayHandler)
    return () => window.removeEventListener('overlay', overlayHandler)
  }, [])

  return (
    <Fragment>
      <Box sx={{ position: 'fixed' }}>
        <img src={OverlayImage} height="128px" width="1920px" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '128px',
          width: '1920px',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
        }}
      >
        {show && (
          <Fragment>
            <Typography
              component="h3"
              variant="h3"
              sx={{ paddingRight: 2 }}
              color={blue[500]}
            >
              {playing?.name ? playing.name : 'Anonymous'} donated
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={red[700]}
              sx={{ paddingRight: 4 }}
            >
              £{playing?.amount}!
            </Typography>
          </Fragment>
        )}
      </Box>
    </Fragment>
  )
}
