import {
  useLocation,
  Navigate,
  useHref,
  useLinkClickHandler,
} from 'react-router-dom'
import { useStateProvider } from '../../context'
import { registerUser } from '../../api/helpers'
import * as AppRoutes from '../../constants/routes'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Copyright } from '../../components/auth'
import { useFeatures } from '../../hooks/features'

const theme = createTheme()

export const RegisterPage = () => {
  const { state, helpers } = useStateProvider()
  const { isActive } = useFeatures()
  const location = useLocation()
  const loginOnClick = useLinkClickHandler(AppRoutes.LOGIN)
  const loginHref = useHref(AppRoutes.LOGIN)
  if (
    !isActive('register') ||
    (location.pathname === '/register' && state.user)
  ) {
    return <Navigate replace to="/" />
  }

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    debugger
    const registerData = {
      name: data.get('name'),
      email: data.get('email'),
      emailConfirmation: data.get('emailConfirmation'),
      password: data.get('password'),
      passwordConfirmation: data.get('confirmPassword'),
    }
    registerUser(registerData).then(({ user, errors }) => {
      if (errors) {
        // TODO: Implement error handling.
        console.log('Errors while attempting register', errors)
      } else {
        helpers.setUser(user)
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            height: 'calc(100vh - 64px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="username"
                    name="name"
                    required
                    fullWidth
                    id="name"
                    label="Username"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="emailConfirmation"
                    label="Confirm Email"
                    name="emailConfirmation"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                  />
                </Grid>
                {/* <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email." />
                            </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link onClick={loginOnClick} href={loginHref} variant="body2">
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
