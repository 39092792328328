import { useContext } from 'react'
import { createStateProvider } from 'react-yahl'
import { api } from '../api'
import { safeResponseData } from 'resource-endpoint'

export const actions = {
  SET_FEATURES: 'features',
  SET_INITIALIZED: 'initialized',
  SET_OVERLAY: 'overlay',
  SET_USER: 'user',
}

/**
 * @module Context
 */

/**
 * Initial state
 * @typedef {object} Context.State
 * @property {string[]|null} features
 * @property {boolean} initialized
 * @property {WindowProxy|null} overlay
 * @property {object|null} user
 */

/**
 * @constant
 * @type {Context.State}
 */
const initialState = {
  [actions.SET_FEATURES]: null,
  [actions.SET_INITIALIZED]: false,
  [actions.SET_OVERLAY]: null,
  [actions.SET_USER]: null,
}

export const [Context, Store] = createStateProvider({
  actions,
  initialState,
  providerHelpers: () => ({
    setUser: function (user) {
      this.action(actions.SET_USER, user)
    },
    setFeatures: function (features) {
      this.action(actions.SET_FEATURES, features)
    },
    setOverlay: function (overlayProxy = null) {
      this.action(actions.SET_OVERLAY, overlayProxy)
    },
    appInitialized: function (value = true) {
      this.action(actions.SET_INITIALIZED, value)
    },
    initializeUser: function () {
      api.user
        .current()
        .then(response => {
          const { features, user } = safeResponseData(response)
          const isOk = response.status === 200
          if (isOk && user) {
            this.setUser(user)
          } else if (
            !isOk &&
            response.status !== 401 &&
            response.status !== 403
          ) {
            console.log(
              'error when calling user.current() in initializeUser',
              response,
            )
          }
          this.setFeatures(features)
          this.appInitialized()
        })
        .catch(err => {
          console.error(
            'Error was thrown when calling user.current() in initializeUser',
            err,
          )
        })
    },
  }),
})

export const useStateProvider = () => useContext(Context)
