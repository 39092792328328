import React, { Fragment, useEffect } from 'react'
import { useStateProvider } from '../../context'
import { LoginPage } from '../../pages/auth/login'
import { api } from '../../api'
import { CircularProgress } from '@mui/material'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { safeResponseData } from 'resource-endpoint'

export const AuthPageWrapper = props => {
  const { state } = useStateProvider()
  return <Fragment>{state.user ? props.children : <LoginPage />}</Fragment>
}

export function DetectAuth(props) {
  const { state, helpers } = useStateProvider()

  useEffect(() => {
    if (!state.initialized) {
      helpers.initializeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialized])
  return (
    <Fragment>
      {!state.initialized ? <CircularProgress /> : props.children}
    </Fragment>
  )
}

export const Copyright = props => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.copyright.gov/" target="_blank">
        Gross Gore
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
