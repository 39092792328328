import {
  ApiMixin,
  AxiosErrorMixin,
  UserEndpoint,
  HandleErrorMixin,
  SessionCsrfCookieMixin,
  FormMixin,
} from 'resource-endpoint'

class UserBase extends SessionCsrfCookieMixin(
  ApiMixin(FormMixin(UserEndpoint)),
) {}

class UserQuery extends AxiosErrorMixin(UserBase) {}

export class User extends HandleErrorMixin(UserBase) {
  /**
   *
   * @returns {UserQuery}
   */
  get reactQuery() {
    return new UserQuery()
  }
}
