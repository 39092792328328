import { useMutation, useQuery } from 'react-query'
import { messageAudio } from '../api/helpers'
import { safeResponseData } from 'resource-endpoint'
import { api } from '../api'

const ORDER = 'order'
const DONATIONS = 'Donations'
const AUDIO = `${DONATIONS}-Audio`

/**
 *
 * @param {{page: number, perPage: number=, since: string=}} options
 * @returns {UseQueryResult<Donation.Pagination, AxiosError>}
 */
export const useDonationPagination = ({ page, perPage, since }) => {
  return useQuery(
    [DONATIONS, page, perPage, since],
    async () =>
      safeResponseData(
        await api.donation.reactQuery.getDonations(since, page, perPage),
      ),
    {
      // Refetch the data every second(s)
      refetchInterval: 30000,
      keepPreviousData: true,
    },
  )
}

/**
 *
 * @param {number} id
 * @returns {UseQueryResult<*, AxiosError>}
 */
export const useDonationOrder = id => {
  return useQuery([ORDER, id], async () => {
    const { donation, order } = safeResponseData(
      await api.donation.reactQuery.getOrder(id),
    )
    return { donation, order }
  })
}

/**
 * TODO: Should we use react-query for this, should it be useQuery or useMutation?
 *
 * @returns {UseMutationResult<HTMLAudioElement, AxiosError, {id: string}, unknown>}
 */
export const useDonationAudio = () => {
  return useMutation(AUDIO, async id => {
    const { file, type } = safeResponseData(
      await api.donation.reactQuery.fetchDonationAudio(id),
    )
    return await messageAudio({ file, type })
  })
}
