import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDonationOrder } from '../../hooks/donations'
import Typography from '@mui/material/Typography'
import { green } from '@mui/material/colors'

export const OrderDetailsPage = () => {
  const { donationId } = useParams()
  const { isLoading, data, error } = useDonationOrder(donationId)
  const order = data?.order
  const donation = data?.donation
  let currencyCode = ''
  if (donation?.currency_code) {
    // TODO: Only two supported, could make a helper function.
    currencyCode = donation?.currency_code === 'GBP' ? '£' : '$'
  }
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : // TODO: Enhance error display
          error ? (
            <>
              <h1>Error</h1>
              <p>{JSON.stringify(error)}</p>
            </>
          ) : (
            <>
              <h2>Donation</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography component="p" sx={{ paddingRight: 1 }}>
                  ID: {donation.id}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography component="p" sx={{ paddingRight: 1 }}>
                  Name: {donation.name ? donation.name : 'Anonymous'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant="p">
                  Amount: {currencyCode}
                  {donation.amount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  component="p"
                >
                  Message: {donation.message}
                </Typography>
              </Box>
              <h2>PayPal Order</h2>
              <pre>{JSON.stringify(order, null, 4)}</pre>
            </>
          )}
        </Box>
      </Box>
    </Container>
  )
}
